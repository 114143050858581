import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styles: []
})
export class FooterComponent implements OnInit {

  currentYear: number = new Date().getFullYear();

  constructor(@Inject(LOCALE_ID) public localeId: string) { }

  ngOnInit() {
  }

}
