import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DidSearchService } from './did-search.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'lodash';
import { OrderingService } from '../ordering/ordering.service';

export interface DID {
    city: string;
    country: string;
    didNumber: string;
    stateProvince: string;
    checked?: boolean;
    pilotNumber?: string;
    disabled?: boolean;
}

@Component({
    selector: 'app-did-search',
    templateUrl: './did-search.component.html',
    styleUrls: ['./did-search.component.css']
})
export class DidSearchComponent implements OnInit {
    DIDPopupOpened = false;
    loaderIsVisible = true;
    pilotNumber: string;
    pilotNumberICC: string;
    city: string;
    stateProvince: string;
    npa: string;
    nxx: string;
    quantity: string;
    addedOpened = false;
    data: DID[] = [];
    allSelected = false;
    dataSelected: DID[] = [];
    dataSelectedLength = 0;
    serverError = false;
    apiSource: string;
    selectionReservationID: string;
    /**/
    startItem = 0;
    endItem = 10;
    currentPage = 1;
    itemsPerPage = 10;
    sortColumn = '';
    sortDescending = true;
    constructor(private activatedRoute: ActivatedRoute, private didSearchService: DidSearchService, private orderingService: OrderingService, private router: Router) { }

    ngOnInit() {
        this.loaderIsVisible = false;
        this.activatedRoute.queryParams.subscribe(params => {
            this.pilotNumber = params.pilotNumber;
            this.pilotNumberICC = params.pilotNumberICC;
            this.city = params.city;
            this.stateProvince = params.stateProvince;
            this.npa = params.npa;
            this.nxx = params.nxx;
            this.quantity = params.quantity;
            this.onCloseDID();
            if (this.quantity && this.pilotNumber) {
                this.loaderIsVisible = true;
                this.onSearch();
            }
        });
    }

    /*search functionality*/
    onSearch() {
        if (this.npa) {
            this.didSearchService.getSearchNpa(this.npa, this.nxx, this.quantity)
                .pipe(
                    finalize(() => {
                        this.loaderIsVisible = false;
                        this.updateView();
                    }))
                .subscribe(
                    (response) => {
                        this.data = response.availableNumbers;
                        this.apiSource = response.apiSource;
                        this.selectionReservationID = response.selectionReservationID;
                        this.serverError = false;
                    },
                    (error) => {
                        this.serverError = true;
                    }
                );
        } else if (this.city && this.stateProvince) {
            this.didSearchService.getSearchCity(this.city, this.stateProvince, this.quantity)
                .pipe(
                    finalize(() => {
                        this.loaderIsVisible = false;
                        this.updateView();
                    }))
                .subscribe(
                    (response) => {
                        this.data = response.availableNumbers;
                        this.apiSource = response.apiSource;
                        this.selectionReservationID = response.selectionReservationID;
                        this.serverError = false;
                    },
                    (error) => {
                        this.serverError = true;
                    }
                );
        }
    }
    updateView() {
        this.itemsPerPage = 10;
        this.onPageChange(1);
        this.sortColumn = '';
        this.sortDescending = true;
        this.dataSelected = [];
    }

    /*DID toggle*/
    onToggleDID(index: number) {
        const tempIndex = index + this.itemsPerPage * (this.currentPage - 1);
        this.data[tempIndex].checked = !this.data[tempIndex].checked;
        this.onUpdateDataSelected();
    }
    onToggleAllDID() {
        const value = (this.ifAllDIDSelected()) ? false : true;
        const endItem = (this.endItem > this.data.length) ? this.data.length : this.endItem;
        for (let index = this.startItem; index < endItem; index++) {
            this.data[index].checked = value;
        }
        this.onUpdateDataSelected();
    }
    ifAllDIDSelected() {
        const tempData = this.data.slice(this.startItem, this.endItem);
        return (tempData.length && tempData.filter((item) => item.checked === true).length === tempData.length) ? true : false;
    }
    onUpdateDataSelected() {
        this.dataSelected = this.data.filter((item) => item.checked === true);
    }

    /*DID popup*/
    onOpenDID() {
        this.DIDPopupOpened = true;
    }
    onCloseDID() {
        this.DIDPopupOpened = false;
    }

    /*ADD DID To Order*/
    onAddAllDidToOrder() {
        this.dataSelected = this.data;
        this.onAddDidToOrder();
    }
    onAddDidToOrder() {
        this.orderingService.update(this.dataSelected, this.pilotNumber, this.apiSource, this.selectionReservationID, this.pilotNumberICC);
        this.router.navigate(['/ordering']);
    }

    /*pages*/
    onPageChange(page: number) {
        this.currentPage = page;
        this.startItem = (page - 1) * this.itemsPerPage;
        this.endItem = this.startItem + this.itemsPerPage;
    }

    onSetItemPerPage(value: string) {
        this.itemsPerPage = +value;
        this.onPageChange(this.currentPage);
    }

    onSortData(value: string) {
        if (this.sortColumn === value) {
            this.sortDescending = !this.sortDescending;
            this.data = this.data.reverse();
        } else {
            this.sortDescending = true;
            this.data = this.data.sort((a, b) => (a[value] > b[value]) ? 1 : ((b[value] > a[value]) ? -1 : 0));
        }
        this.sortColumn = value;
    }

    getSortClass(value: string) {
        if (this.sortColumn === value) {
            return (this.sortDescending) ? 'desc' : 'asc';
        } else {
            return '';
        }
    }

}
