import { BehaviorSubject } from 'rxjs';

export class RouterUrlService {

    private routerUrl = '';
    routerCallback = new BehaviorSubject(null);

    setRouterUrl(text) {
        console.log('%cROUTER: ' + text, 'color: green');
        this.routerUrl = text;
        this.routerCallback.next(this.routerUrl);
    }

    getRouterUrl() {
        return this.routerUrl;
    }
}
