import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../../_shared/services/auth.service';
let header: any;

@Injectable()
export class ApiService {
  currentUser: any;
  headers: any;
  constructor(private http: HttpClient, private auth: AuthService) {
    this.currentUser = this.auth.getCookie('user_access_token');
    header = new HttpHeaders({'Content-Type': 'application/json', 'Authorization': 'Bearer ' + this.currentUser});
  }

 /***************/
 /*INVENTORY API*/
 /***************/

 /*GET INVENTORY*/

  getInventories(number: any) {
      return this.http.get(environment.endpoint_core + '/subscribers/' + number + '/pilotnumbers', {headers: header})
        .pipe(
           map((response: any) => {
                 return response;
           }), catchError(val => throwError(val)));
  }

  /*GET INVENTORY DETAIL*/

  getInventoryDetail(id: any, number: any) {
      return this.http.get(environment.endpoint_core + '/subscribers/' + id + '/pilotnumbers/' + number, {headers: header})
        .pipe(
           map((response: any) => {
                 return response;
           }), catchError(val => throwError(val)));
  }

  /*GER 911 INFORMATION*/

  get911Object(number: any) {
      return this.http.get(environment.endpoint_core + '/e911/' + number, {headers: header})
        .pipe(
           map((response: any) => {
                 return response;
           }), catchError(val => throwError(val)));
  }

  /*UPDATE 911 INFORMATION*/

  update911Object(number: any, data: any) {
      return this.http.put(environment.endpoint_core + '/e911/' + number, data, {headers: header})
        .pipe(
           map((response: any) => {
                 return response;
           }), catchError(val => throwError(val)));
  }

}
