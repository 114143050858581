import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_shared/services/auth.service';
import { DID } from '../did-search/did-search.component';
import { DidSearchService } from '../did-search/did-search.service';

@Injectable({ providedIn: 'root' })
export class OrderingService {
    constructor(private httpClient: HttpClient, private Auth: AuthService, private didSearchService: DidSearchService) { }
    private pilotNumber = '';
    private pilotNumberICC = '';
    private data: DID[] = [];
    private apiSource = '';
    private selectionReservationID = '';
    getCart(): DID[] {
        return this.data;
    }
    getPilotNumber(): string {
        return this.pilotNumber;
    }
    update(numbers: DID[], pilotNumber: string, apiSource: string, selectionReservationID: string, pilotNumberICC: string) {
        this.data = numbers.map((item) => {
            item.checked = false;
            return item;
        });
        this.pilotNumber = pilotNumber;
        this.apiSource = apiSource;
        this.selectionReservationID = selectionReservationID;
        this.pilotNumberICC = pilotNumberICC;
    }
    clearOrdering() {
        this.data = [];
        this.pilotNumber = '';
        this.apiSource = '';
        this.selectionReservationID = '';
    }
    place(formValue: any) {
        const value: any = {
            firstName: formValue.firstName,
            lastName: formValue.lastName,
            userEmail: formValue.userEmail,
            userTelephone: (formValue.extension) ? formValue.userTelephone + ' ext. ' + formValue.extension : formValue.userTelephone,
            apiSource: this.apiSource,
            selectionReservationID: this.selectionReservationID,
            PilotICCSubscriberID: this.pilotNumberICC,
            pilots: [
                {
                    pilotNumber: this.pilotNumber,
                    didNumbers: this.data
                }
            ]

        };
        const httpOptions: any = {
            headers: new HttpHeaders().set('Authorization', 'Bearer ' + this.Auth.getToken()),
            responseType: 'text'
        };
        return this.httpClient.post(environment.endpoint_core + '/telephonenumbers/order', value, httpOptions)
            .pipe(
                map(
                    (response: any) => {
                        this.didSearchService.disabled = true;
                        return response;
                    }
                ),
                catchError(val => {
                    this.didSearchService.disabled = false;
                    return throwError(val);
                })
            );
    }
}
