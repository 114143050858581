import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ForgotPasswordService } from './forgotpassword.service';

@Component({
    selector: 'app-forgotpassword',
    templateUrl: './forgotpassword.component.html',
    styles: []
})
export class ForgotpasswordComponent implements OnInit {
    forgotpasswordForm: FormGroup;
    isSubmitted = false;
    errorValue = 0;
    loaderIsVisible = false;
    changeSuccess = false;
    constructor(private forgotPasswordService: ForgotPasswordService) { }

    ngOnInit() {
        this.forgotpasswordForm = new FormGroup({
            'username': new FormControl(null, [Validators.required])
        });
    }

    onSubmit() {
        this.isSubmitted = true;
        if (this.forgotpasswordForm.valid) {
            this.loaderIsVisible = true;
            this.forgotPasswordService.requestChange(this.forgotpasswordForm.get('username').value)
                .subscribe(
                    (response: string) => {
                        this.loaderIsVisible = false;
                        console.log('%c' + response, 'color: green');
                        this.changeSuccess = true;
                    },
                    (error) => {
                        this.loaderIsVisible = false;
                        console.log('%c' + error, 'color: red');
                        this.errorValue = -1;
                    }
                );
        }
    }

    setValid() {
        this.errorValue = 0;
    }

}
