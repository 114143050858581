import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-action',
  templateUrl: './action.component.html'
})
export class ActionComponent implements OnInit {
  open = false;

  constructor() { }

  ngOnInit() {
  }

  openDropDown() {
      this.open = !this.open;
  }

  closeDropDown() {
      this.open = false;
  }

}
