import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ResetPasswordService {
    constructor(private httpClient: HttpClient) { }
    requestReset(value, token) {
        return this.httpClient.post(environment.endpoint_auth + '/api/v1/password/reset?token=' + token, {'token': token, 'password': value})
            .pipe(
                map(
                    (response: any) => {
                        return 'Reset password request success';
                    }
                ),
                catchError(val => throwError('Reset password request error'))
            );
    }
}
