import {
    Component,
    OnInit,
    Input,
    ViewChild,
    ElementRef,
    AfterViewInit,
    OnDestroy,
    SimpleChanges,
    SimpleChange, OnChanges
} from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { numberPattern } from '../_shared/patterns';
import { fromEvent, Subscription } from 'rxjs';
import { debounceTime, switchMap, distinctUntilChanged, map, filter } from 'rxjs/operators';
import { DidLocationService } from './did-location.service';

@Component({
    selector: 'app-did-location',
    templateUrl: './did-location.component.html',
    styleUrls: ['./did-location.component.css']
})
export class DidLocationComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    didSearchNpa: FormGroup;
    didSearchCity: FormGroup;
    tab = 0;
    @Input() pilotNumber: number;
    @Input() pilotNumberICC: number;
    @Input() setPristine: boolean;
    @ViewChild('f') inputElement: ElementRef;
    searchSubscription: Subscription;
    cities: any[] = [];
    states: any[] = [];
    cityOpen = true;
    cityPending = false;
    errorDisplay = false;
    disabledProvince = true;
    constructor(private router: Router, private didLocationService: DidLocationService) { }

    ngOnChanges(changes: SimpleChanges) {
        const setPristine: SimpleChange = changes.setPristine;
        if (setPristine && setPristine.currentValue && this.didSearchCity && this.didSearchNpa) {
            this.didSearchCity.markAsPristine();
            this.didSearchNpa.markAsPristine();
        }
    }
    ngOnInit() {
        this.didSearchNpa = new FormGroup({
            'npa': new FormControl(null, [Validators.required, Validators.minLength(3), Validators.maxLength(3), Validators.pattern(numberPattern)]),
            'nxx': new FormControl(null, [Validators.minLength(3), Validators.maxLength(3), Validators.pattern(numberPattern)]),
            'quantity': new FormControl(null, [Validators.required, Validators.min(1), Validators.max(100), Validators.pattern(numberPattern)])
        });

        this.didSearchCity = new FormGroup({
            'city': new FormControl(null, [Validators.required]),
            'stateProvince': new FormControl(null, [Validators.required]),
            'quantity': new FormControl(null, [Validators.required, Validators.min(1), Validators.max(100), Validators.pattern(numberPattern)])
        });
    }

    ngAfterViewInit() {
        this.searchSubscription = fromEvent(this.inputElement.nativeElement, 'keyup').pipe(
            map(() => this.inputElement.nativeElement.value),
            debounceTime(500),
            distinctUntilChanged(),
            filter(() => this.inputElement.nativeElement.value.length >= 2),
            switchMap(() => {
                this.cityPending = true;
                return this.didLocationService.getCity(this.inputElement.nativeElement.value);
            }),
        ).subscribe(
            (response) => {
                this.cityPending = false;
                this.states = [];
                this.cities = response;
                this.didSearchCity.get('stateProvince').setValue(null);
                this.disabledProvince = true;
                if (response === 'error') {
                    this.errorDisplay = true;
                } else {
                    this.errorDisplay = false;
                }
                this.onCityOpen();
            }
        );
    }

    // city select
    onCitySelect(city: any) {
        this.states = this.cities.filter((value) => value.city === city.city);
        this.cities = [];
        this.didSearchCity.get('city').setValue(city.city);
        this.didSearchCity.get('stateProvince').setValue(city.country + '|' + city.stateProvince);
        this.disabledProvince = false;
        this.errorDisplay = false;
        this.onCityClose();
    }

    onCityClose() {
        this.cityOpen = false;
    }

    onCityOpen() {
        this.cityOpen = true;
    }


    onSelectTab(tab: number) {
        this.tab = tab;
    }

    onDidSearch(formGroup: FormGroup) {
        const abc = this.setPristine;
        if (formGroup.valid && this.pilotNumber) {
            const value = formGroup.value;
            value.pilotNumber = this.pilotNumber;
            value.pilotNumberICC = this.pilotNumberICC;
            if (value.stateProvince) {
                const sp = value.stateProvince.split('|');
                if (sp && sp.length > 1) {
                    value.stateProvince = sp[1];
                }
            }
            this.router.navigate(['/didsearch'], { queryParams: value });
        }
    }

    ngOnDestroy() {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }

}
