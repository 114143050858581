import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class DidSearchService {
    disabled = false;
    constructor(private httpClient: HttpClient, private Auth: AuthService) { }
    getSearchNpa(npa: string, nxx: string, quantity: string) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.Auth.getToken());
        let link;
        if (nxx) {
            link = environment.endpoint_core + '/telephonenumbers/available/npanxx?npa=' + npa + '&nxx=' + nxx + '&quantity=' + quantity;
        } else {
            link = environment.endpoint_core + '/telephonenumbers/available/npa?npa=' + npa + '&quantity=' + quantity;
        }
        return this.httpClient.get(link, { headers: headers })
            .pipe(
                map(
                    (response: any) => {
                        return response;
                    }
                ),
                catchError(val => {
                    return throwError(val);
                })
            );
    }

    getSearchCity(city: string, stateProvince: string, quantity: string) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.Auth.getToken());
        return this.httpClient.get(environment.endpoint_core + '/telephonenumbers/available/citystate?city=' + city + '&stateProvince=' + stateProvince + '&quantity=' + quantity, { headers: headers })
            .pipe(
                map(
                    (response: any) => {
                        return response;
                    }
                ),
                catchError(val => {
                    return throwError(val);
                })
            );
    }
}
