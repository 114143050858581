import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'filterText'})
export class FilterPipeByValue implements PipeTransform {
transform(items: any[], searchText: any, valueName?: any): any[] {
    if (!items) { return []; }
        if (!searchText) { return items; }
            searchText = searchText.toLowerCase();
            if (valueName) {
                return items.filter(it => {
                    if (it[valueName].toString()) {
                        return it[valueName].toString().toLowerCase().includes(searchText);
                    }
                });
            }
    }
}
