import { Component, OnInit, LOCALE_ID, Inject } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { AuthService } from '../../_shared/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styles: []
})
export class LoginComponent implements OnInit {
    signinForm: FormGroup;
    isSubmitted = false;
    errorValue = 0;
    loaderIsVisible = false;
    termsPopup = false;
    constructor(private Auth: AuthService, private router: Router, @Inject(LOCALE_ID) public localeId: string) { }

    ngOnInit() {
        this.signinForm = new FormGroup({
            'username': new FormControl(null, [Validators.required]),
            'password': new FormControl(null, Validators.required)
        });
    }

    onSubmit() {
        this.isSubmitted = true;
        if (this.signinForm.valid) {
            this.onLogin();
        }
    }
    onTermsPopupAccept() {
        this.router.navigate(['/inventory']);
    }

    onLogin() {
        this.termsPopup = false;
        if (this.signinForm.valid) {
            this.loaderIsVisible = true;
            this.Auth.login(this.signinForm.value)
                .subscribe(
                    (response: string) => {
                        console.log('%c' + response, 'color: green');
                        this.termsPopup = true;
                        this.loaderIsVisible = false;
                    },
                    (error) => {
                        this.loaderIsVisible = false;
                        if (error.error.error_description === 'AccountLocked') {
                            this.errorValue = 1;
                        } else if (error.error.error_description === 'Bad credentials') {
                            this.errorValue = 2;
                        } else { this.errorValue = -1; }
                    }
                );
        }
    }

    setValid() {
        this.errorValue = 0;
    }

    onReset() {
        this.signinForm.reset();
    }

    onTermsPopupClose() {
        this.termsPopup = false;
        this.Auth.logout();
    }

}
