import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './modules/app.routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { PortalComponent } from './portal/portal.component';
import { ForgotpasswordComponent } from './portal/forgotpassword/forgotpassword.component';
import { LoginComponent } from './portal/login/login.component';
import { ResetpasswordComponent } from './portal/resetpassword/resetpassword.component';
import { InventoryComponent } from './inventory/inventory.component';
import { InventoryDetailComponent } from './inventory/inventory-detail/inventory-detail.component';
import { PasswordComponent } from './password/password.component';
import { ProfileComponent } from './profile/profile.component';
import { TypographyComponent } from './typography/typography.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LanguagesComponent } from './languages/languages.component';

import { RouterUrlService } from './_shared/services/router.url.service';
import { AuthService } from './_shared/services/auth.service';
import { AuthGuard } from './_shared/services/auth.guard.service';
import { RedirectGuard } from './_shared/services/redirect.guard.service';
import { ForgotPasswordService } from './portal/forgotpassword/forgotpassword.service';
import { ResetPasswordService } from './portal/resetpassword/resetpassword.service';
import {AuthInterceptor, DEFAULT_TIMEOUT} from './_shared/services/auth.interceptor';
import { ClickInputDirective } from './_shared/directives/click-input.directive';
import { ClickElsewhereDirective } from './_shared/directives/click-elsewhere.directive';

import { ApiService } from './_shared/services/api.service';
import { OrderBy } from './_shared/pipes/orderby.pipe';
import { StatusFilterPipe } from './_shared/pipes/filter.pipe';
import { FilterPipeByValue } from './_shared/pipes/search.pipe';
import { MaxMinPipe } from './_shared/pipes/maxmin.pipe';
import { ActionComponent } from './action/action.component';
import { DidLocationComponent } from './did-location/did-location.component';
import { DidSearchComponent } from './did-search/did-search.component';
import { OrderingComponent } from './ordering/ordering.component';
import { DidFormat } from './did-search/did.pipe';
import { PhoneMaskDirective } from './_shared/directives/phone-mask.directive';
import { SearchGuard } from './_shared/services/search.guard.service';

@NgModule({
    declarations: [
        AppComponent,
        PortalComponent,
        ForgotpasswordComponent,
        LoginComponent,
        ResetpasswordComponent,
        InventoryComponent,
        InventoryDetailComponent,
        PasswordComponent,
        ProfileComponent,
        TypographyComponent,
        HeaderComponent,
        FooterComponent,
        LanguagesComponent,
        ClickInputDirective,
        ClickElsewhereDirective,
        OrderBy,
        StatusFilterPipe,
        FilterPipeByValue,
        MaxMinPipe,
        ActionComponent,
        DidLocationComponent,
        DidSearchComponent,
        OrderingComponent,
        DidFormat,
        PhoneMaskDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgbModule,
        ReactiveFormsModule,
        HttpClientModule,
        FormsModule
    ],
    providers: [
        RouterUrlService,
        AuthService,
        AuthGuard,
        SearchGuard,
        RedirectGuard,
        ForgotPasswordService,
        ResetPasswordService,
        ApiService,
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: DEFAULT_TIMEOUT, useValue: 250000}
    ], bootstrap: [AppComponent]
})
export class AppModule { }
