import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../_shared/services/api.service';
import { Observable } from 'rxjs';
import { AuthService } from '../_shared/services/auth.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
    selector: 'app-inventory',
    templateUrl: './inventory.component.html',
    styles: []
})
export class InventoryComponent implements OnInit {
    currentPage = 1;
    itemsPerPage = 10;
    endItemPerPage = 10;
    startItemPerPage = 0;
    tableMenuOpened = false;
    loaderIsVisible = false;
    sort: any = {
        column: '',
        descending: false
    };
    filterForm: FormGroup;
    minChannelNumber: any;
    maxChannelNumber: any;
    collectionSize: any;
    desc = false;
    filterWithSorting: string;

    /*filter by pilot number*/

    pilotNumberValue: any;
    searchTextNumber = '';
    editArray: any = [];
    inventoryArr: any = [];
    subscribeId: any;
    numberSubscribeId: any;

    filterItems: any = [{
        value: 'active',
        checked: false,
        name: 'In Service'
        },
        {
        value: 'inprogress',
        checked: false,
        name: 'In Progress'
        },
        {
        value: 'inactive',
        checked: false,
        name: 'In Active'
    }];

    DIDPopupOpened = false;
    pilotNumber: number;
    pilotNumberICC: number;

    constructor(private api: ApiService, private Auth: AuthService) {
        this.filterForm = new FormGroup({
            minNumber: new FormControl(''),
            maxNumber: new FormControl(''),
            active: new FormControl(''),
            inprogress: new FormControl(''),
            inactive: new FormControl('')
        });

        this.pilotNumberValue = 'pilotNumber';
        this.subscribeId = Auth.getDecodedToken();
        if (this.subscribeId.user_details) {
            this.numberSubscribeId = this.subscribeId.user_details.iccId;
        }
    }
    selectPage(page: any) {
        this.currentPage = page;
        this.endItemPerPage = this.itemsPerPage * page;
        this.startItemPerPage = this.endItemPerPage - this.itemsPerPage;
    }

    setItemPerPage(num: any) {
        this.itemsPerPage = num;
        this.endItemPerPage = this.itemsPerPage;
        this.startItemPerPage = 0;
    }

    ngOnInit() {
        this.getInventoryList();
    }

    /*table sorting*/

    getSortClass(value) {
        if (this.sort.column === value && this.sort.descending) { return 'asc'; } else if (this.sort.column === value && !this.sort.descending) { return 'desc'; }
    }

    changeSorting(column) {
        const sort = this.sort;
        if (sort.column === column) {
            sort.descending = !sort.descending;
        } else {
            sort.column = column;
            sort.descending = false;
        }
    }

    changeFilterSorting(val) {
        this.filterWithSorting = val;
        this.desc = !this.desc;
    }

    getSortFilterClass(val) {
        if (this.filterWithSorting === val && this.desc) { return 'active asc'; } else if (this.filterWithSorting === val && !this.desc) { return 'active desc'; }
    }

    /*get inventory list*/

    getInventoryList() {
        this.loaderIsVisible = true;
        this.api.getInventories(this.numberSubscribeId).subscribe(
            (response: any) => {
            console.log(response);
                this.inventoryArr = response;
                this.collectionSize =  this.inventoryArr.length;
                this.loaderIsVisible = false;
            }, (error) => {
                this.loaderIsVisible = false;
        });
    }

    /*filter inventory list*/

    filterArray() {
    const statusSelect1 = this.filterItems[0].checked === true ? this.filterItems[0].value : false;
    const statusSelect2 = this.filterItems[1].checked === true ? this.filterItems[1].value : false;
    const statusSelect3 = this.filterItems[2].checked === true ? this.filterItems[2].value : false;
    const minNum = this.minChannelNumber;
    const maxNum = this.maxChannelNumber;
    const filteredArray = [];
    this.sort = {
        column: this.filterWithSorting,
        descending: this.desc
    };

    if (((minNum && maxNum) || (statusSelect1 !== false || statusSelect2 !== false || statusSelect3 !== false))) {
        this.loaderIsVisible = true;
        this.inventoryArr = [];
        this.api.getInventories(this.numberSubscribeId)
        .subscribe((response: any) => {
            this.inventoryArr = response.filter((item) => {
            const status = item.numberStatus.toLowerCase();
            if ((minNum && maxNum) && (statusSelect1 !== false || statusSelect2 !== false || statusSelect3 !== false)) {
                if (+item['numberOfChannels'] >= +minNum && +item['numberOfChannels'] <= +maxNum) {
                    if (status === statusSelect1 || status === statusSelect2 || status === statusSelect3) {
                        filteredArray.push(item);
                    }
                }
            } else {
            if ((minNum && maxNum)) {
                if (+item['numberOfChannels'] >= +minNum && +item['numberOfChannels'] <= +maxNum) {
                    filteredArray.push(item);
                }
            } else {
                if ((status === statusSelect1 || status === statusSelect2 || status === statusSelect3)) {
                    filteredArray.push(item);
                }
            }
            }
            });
            this.collectionSize = filteredArray.length;
            this.inventoryArr = filteredArray;
        })
        .add(() => {
            this.loaderIsVisible = false;
        });
    } else {
        if (!this.inventoryArr.length) {
            this.getInventoryList();
        }
    }
    }

    exportFile() {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            useBom: true,
            noDownload: false,
            headers: ['Pilot Number', 'Number Of Channels', 'Status']
        };
        const data = [];
        this.inventoryArr.map((item) => {
            data.push({
                pilotNumber: item.pilotNumber,
                numberOfChannels: item.numberOfChannels,
                numberStatus: item.numberStatus
            });
        });
        return new AngularCsv(data, 'Report_pilot_numbers', options);

//        this.api.getInventoryDetail(this.numberSubscribeId, number).subscribe(
//        (response: any) => {
//            console.log(response);
//            const data = response.telephoneNumbers;
//            return new Angular5Csv(data, 'Report_' + number, options);
//        });
    }

    /*reset filter inventory list*/

    resetFilter() {
        this.sort = {
            column: '',
            descending: false
        };
        this.filterWithSorting = '';
        this.getInventoryList();
    }

    onToggleTableMenu() {
        this.tableMenuOpened = !this.tableMenuOpened;
    }
    onCloseTableMenu() {
        this.tableMenuOpened = false;
    }

    /*DID popup*/
    onOpenDID(pilotNumber, subscriberID) {
        this.DIDPopupOpened = true;
        this.pilotNumber = pilotNumber;
        this.pilotNumberICC = subscriberID;
    }
    onCloseDID() {
        this.DIDPopupOpened = false;
    }
}
