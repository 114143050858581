import { Directive, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[formControlName][appPhoneMask]',
})
export class PhoneMaskDirective {

    constructor(public ngControl: NgControl) { }

    // @HostListener('ngModelChange', ['$event'])
    // onModelChange(event) {
    //     this.onInputChange(event);
    // }

    @HostListener('input', ['$event'])
    onKeyup(event) {
        this.onInputChange(event.target.value);
    }

    onInputChange(event) {
        let newVal = event.replace(/\D/g, '').substring(0, 10);
        if (newVal.length === 0) {
            newVal = '';
        } else if (newVal.length <= 3) {
            newVal = newVal.replace(/^(\d{0,3})/, '($1');
        } else if (newVal.length <= 6) {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
        } else {
            newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,4})/, '($1) $2-$3');
        }
        // this.ngControl.valueAccessor.writeValue(newVal);
        this.ngControl.control.setValue(newVal);
    }
}
