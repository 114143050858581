import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PortalComponent } from '../portal/portal.component';
import { LoginComponent } from '../portal/login/login.component';
import { ForgotpasswordComponent } from '../portal/forgotpassword/forgotpassword.component';
import { ResetpasswordComponent } from '../portal/resetpassword/resetpassword.component';
import { InventoryComponent } from '../inventory/inventory.component';
import { InventoryDetailComponent } from '../inventory/inventory-detail/inventory-detail.component';
import { PasswordComponent } from '../password/password.component';
import { ProfileComponent } from '../profile/profile.component';
import { TypographyComponent } from '../typography/typography.component';
import { AuthGuard } from '../_shared/services/auth.guard.service';
import { RedirectGuard } from '../_shared/services/redirect.guard.service';
import { OrderingComponent } from '../ordering/ordering.component';
import { DidSearchComponent } from '../did-search/did-search.component';
import { CanDeactivateGuard } from '../_shared/services/can-deactivate-guard.service';
import { SearchGuard } from '../_shared/services/search.guard.service';


const appRoutes: Routes = [
    { path: 'portal', component: PortalComponent, data: { animation: 'portal' }, children: [
        { path: '', redirectTo: '/portal/login', pathMatch: 'full' },
        { path: 'login', component: LoginComponent, data: { animation: 'login' }, canActivate: [RedirectGuard] },
        { path: 'forgotpassword', component: ForgotpasswordComponent, data: { animation: 'forgotpassword' }, canActivate: [RedirectGuard] },
        { path: 'resetpassword', component: ResetpasswordComponent, data: { animation: 'resetpassword' } }
    ] },
    { path: 'didsearch', component: DidSearchComponent, data: { animation: 'didsearch' }, canActivate: [AuthGuard, SearchGuard] },
    { path: 'ordering', component: OrderingComponent, data: { animation: 'ordering' }, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
    { path: 'inventory', component: InventoryComponent, data: { animation: 'inventory' }, canActivate: [AuthGuard] },
    { path: 'inventory/:id', component: InventoryDetailComponent, data: { animation: 'inventorydetail' }, canActivate: [AuthGuard] },
    { path: 'password', component: PasswordComponent, data: { animation: 'password' }, canActivate: [AuthGuard] },
    { path: 'profile', component: ProfileComponent, data: { animation: 'profile' }, canActivate: [AuthGuard] },
    { path: 'typography', component: TypographyComponent, data: { animation: 'typography' }, canActivate: [AuthGuard] },
    { path: '**', redirectTo: '/portal/login' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes)
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {

}
