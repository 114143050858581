import { Component, OnInit, HostListener } from '@angular/core';
import * as _ from 'lodash';
import { DID } from '../did-search/did-search.component';
import { OrderingService } from './ordering.service';
import { finalize } from 'rxjs/operators';
import { CanComponentDeactivate } from '../_shared/services/can-deactivate-guard.service';
import { Subject, Observable } from 'rxjs';
import { Location } from '@angular/common';
import { AuthService } from '../_shared/services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { phonePattern, numberPattern } from '../_shared/patterns';

@Component({
    selector: 'app-ordering',
    templateUrl: './ordering.component.html',
    styleUrls: ['./ordering.component.css']
})
export class OrderingComponent implements OnInit, CanComponentDeactivate {
    confirmationOpened = false;
    data: DID[] = [];
    orderingSuccess = false;
    orderingFailed = false;
    loaderIsVisible = false;
    navigateAwaySelection$: Subject<boolean> = new Subject<boolean>();
    pilotNumber: string;
    date: Date;
    componentForm: FormGroup;
    user: any;
    @HostListener('window:beforeunload') alertConfirm() {
        if (!this.data.length) {
            return true;
        }
        return false;
    }
    /**/
    constructor(private orderingService: OrderingService,
                private location: Location,
                private Auth: AuthService) { }

    ngOnInit() {
        this.data = this.orderingService.getCart();
        this.pilotNumber = this.orderingService.getPilotNumber();

        this.componentForm = new FormGroup({
            'userTelephone': new FormControl(null, [Validators.required, Validators.pattern(phonePattern)]),
            'extension': new FormControl(null, [Validators.pattern(numberPattern)]),
            'firstName': new FormControl(null, [Validators.required]),
            'lastName': new FormControl(null, [Validators.required]),
            'userEmail': new FormControl(null, [Validators.required]),
        });

        this.user = this.Auth.decodeToken();
        this.user.userEmail = this.user.email;

        this.componentForm.patchValue(this.user);
    }

    /**/
    onGoBack() {
        this.location.back();
    }

    /**/
    onPlace() {
        if (!this.componentForm.valid) {
            return;
        }
        this.loaderIsVisible = true;
        window.scrollTo(0, 0);
        this.orderingService.place(this.componentForm.value)
            .pipe(
                finalize(() => {
                    this.loaderIsVisible = false;
                }))
            .subscribe(
                (response) => {
                    this.date = new Date();
                    this.orderingSuccess = true;
                    this.orderingService.clearOrdering();
                },
                (error) => {
                    this.orderingFailed = true;
                }
            );
    }

    /*deactivate*/
    canDeactivate(): boolean | Observable<boolean> {
        if (this.orderingService.getCart().length) {
            this.confirmationOpened = true;
            return this.navigateAwaySelection$;
        } else {
            return true;
        }
    }
    onStay() {
        this.navigateAwaySelection$.next(false);
        this.confirmationOpened = false;
    }
    onLeave() {
        this.orderingService.clearOrdering();
        this.navigateAwaySelection$.next(true);
        this.confirmationOpened = false;
    }

}
