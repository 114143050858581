import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { DidSearchService } from 'src/app/did-search/did-search.service';

@Injectable()
export class SearchGuard implements CanActivate {
    constructor(private didSearchService: DidSearchService, private router: Router) { }
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (this.didSearchService.disabled === false) { return true; }
        this.router.navigate(['/inventory']);
        this.didSearchService.disabled = false;
        return false;
    }
}
