import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ForgotPasswordService {
    constructor(private httpClient: HttpClient) { }
    requestChange(value) {
        return this.httpClient.post(environment.endpoint_auth + '/api/v1/password/recover?user=' + value.toLowerCase(), { 'user': value })
            .pipe(
                map(
                    (response: any) => {
                        return 'Change password request success';
                    }
                ),
                catchError(val => throwError('Change password request error'))
            );
    }
}
