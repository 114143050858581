import {Inject, Injectable, InjectionToken} from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { tap, timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AuthService } from '../../_shared/services/auth.service';

export const DEFAULT_TIMEOUT = new InjectionToken<number>('defaultTimeout');

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private auth: AuthService, @Inject(DEFAULT_TIMEOUT) protected defaultTimeout: number) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const timeoutValue = Number(this.defaultTimeout);
      request.headers.delete("timeout");
        return next.handle(request).pipe(timeout(timeoutValue),
            tap((event: HttpEvent<any>) => {
                    if (event instanceof HttpResponse) { }
                }, (err: any) => {
                    if (err instanceof HttpErrorResponse) {
                        if (err.status === 401) {
                            console.log('%c INTERCEPTED /!\\ ', 'border: 1px red solid; color: red;');
                            this.auth.logout();
                        }
                    }else if(err.name.toUpperCase() === "TIMEOUTERROR"){
                      console.log('%c timeout occurred /!\\ ', 'border: 1px red solid; color: red;');
                    }
            })
        );
    }
}
