import { Pipe, PipeTransform } from '@angular/core';
@Pipe({name: 'maxmin'})
export class MaxMinPipe implements PipeTransform {
transform( array: Array<any>, from: string, to: string, value: string ): Array<string> {
    if (array !== undefined) {
        const arrayMaxMin = [];
        const valMin = +from, valMax = +to;
            if ((valMin <= valMax) && (valMin !== NaN && valMax !== NaN)) {
                array.filter((val) => {
            if (val[value] >= valMin && val[value] <= valMax) {
                arrayMaxMin.push(val);
            }
            });
            return arrayMaxMin;
        } else {
            return array;
        }
    }
}
}
