import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { throwError, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from '../_shared/services/auth.service';

@Injectable({ providedIn: 'root' })
export class DidLocationService {
    constructor(private httpClient: HttpClient, private Auth: AuthService) { }
    getCity(text: string) {
        const headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.Auth.getToken());
        return this.httpClient.get(environment.endpoint_core + '/cities/search?startsWith=' + text, { headers: headers })
            .pipe(
                map(
                    (response: any) => {
                        return response;
                    }
                ),
                catchError(val => {
                    return of('error');
                })
            );
    }
}
