import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

import {
    trigger,
    style,
    animate,
    transition,
    query,
    group
} from '@angular/animations';
import { RouterUrlService } from './_shared/services/router.url.service';
import { DidSearchService } from './did-search/did-search.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [
        trigger('routerAnimation', [
            transition('portal => *', [
                query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
                group([
                    query(':enter', [
                        style({ transform: 'translateX(-50%)', opacity: '0' }),
                        animate('0.5s cubic-bezier(0.8, 0, 0.2, 1)', style({ transform: 'translateX(0%)', opacity: '1' })),
                    ], { optional: true }),
                    query(':leave', [
                        style({ opacity: '1' }),
                        animate('0.5s cubic-bezier(0.8, 0, 0.2, 1)', style({ opacity: '0' })),
                    ], { optional: true }),
                ])
            ]),
            transition('* => portal', [

            ]),
            transition('* <=> *', [
                query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
                group([
                    query(':enter', [
                        style({ transform: 'translateX(-50%)', opacity: '0' }),
                        animate('0.5s cubic-bezier(0.8, 0, 0.2, 1)', style({ transform: 'translateX(0%)', opacity: '1' })),
                    ], { optional: true }),
                    query(':leave', [
                        style({ opacity: '0' })
                    ], { optional: true }),
                ])
            ])
        ])
    ]
})
export class AppComponent implements OnInit {
    isLoaded: boolean;
    base = '';
    displayNav = false;
    userAgentPhone = false;
    constructor(private router: Router, private routerUrlService: RouterUrlService, private didSearchService: DidSearchService) { }

    getRouteAnimation(outlet) {
        return outlet.activatedRouteData.animation;
    }

    ngOnInit() {
        this.userAgentPhone = (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/webOS/i) || navigator.userAgent.match(/iPhone/i) || navigator.userAgent.match(/iPod/i)) ? true : false;

        setTimeout(() => {
            this.isLoaded = true;
        }, 250);

        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            this.didSearchService.disabled = false;
            window.scrollTo(0, 0);
            this.base = this.router.url;
            if (!this.base.includes('portal')) { this.displayNav = true; } else { this.displayNav = false; }
            this.routerUrlService.setRouterUrl(this.router.url);
        });
    }
}
