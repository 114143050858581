import { Directive, EventEmitter, ElementRef, HostListener, Output, HostBinding } from '@angular/core';

@Directive({ selector: '[appClickElsewhere]' })
export class ClickElsewhereDirective {
    @Output() appClickElsewhere = new EventEmitter<MouseEvent>();
    isClicked = true;

    constructor(private elementRef: ElementRef) { }

    @HostListener('document:click', ['$event'])
    public onDocumentClick(event: MouseEvent): void {
        const targetElement = event.target as HTMLElement;
        if (targetElement && !this.elementRef.nativeElement.contains(targetElement)) {
            this.isClicked = false;
            this.appClickElsewhere.emit(event);
        }
    }
}
