import { Component, OnInit, Input, OnDestroy, LOCALE_ID, Inject } from '@angular/core';
import {
    trigger,
    style,
    animate,
    transition,
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { RouterUrlService } from '../_shared/services/router.url.service';
import { Router } from '@angular/router';
import { AuthService } from '../_shared/services/auth.service';
import { User } from '../_shared/models/user.model';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styles: [],
    animations: [
        trigger('fadeInAnimation', [
            transition(':enter', [
                style({ opacity: '0' }),
                animate('0.5s ease-in-out', style({ opacity: '1' }))
            ]),
        ])
    ]
})
export class HeaderComponent implements OnInit, OnDestroy {
    private userData: User;
    @Input() base: string;
    userMenuOpened = false;
    slideMenuOpened = false;
    rsMenuOpened = false;
    routerCallbackSubscription: Subscription;
    logoutPopupOpened = false;
    page = 1;
    pdfUrl: string;
    // ==>remove router
    constructor(private router: Router, private routerUrlService: RouterUrlService, private Auth: AuthService, @Inject(LOCALE_ID) protected localeId: string) { }

    ngOnInit() {
        this.userData = this.Auth.decodeToken();
        this.routerCallbackSubscription = this.routerUrlService.routerCallback.subscribe((value) => {
            this.userMenuOpened = false;
            this.slideMenuOpened = false;
            this.rsMenuOpened = false;
        });
        this.pdfUrl = (this.localeId === 'fr') ? 'assets/pdf/SIP Portal User Guide FR.pdf' : 'assets/pdf/SIP Portal User Guide EN.pdf';
    }

    ngOnDestroy() {
        this.routerCallbackSubscription.unsubscribe();
    }

    /*user menu*/
    onToggleUserMenu() {
        this.userMenuOpened = !this.userMenuOpened;
    }
    onCloseUserMenu() {
        this.userMenuOpened = false;
    }

    /*slide menu*/
    onToggleSlideMenu() {
        this.slideMenuOpened = !this.slideMenuOpened;
    }

    /*rs menu*/
    onToggleRsMenu() {
        this.rsMenuOpened = !this.rsMenuOpened;
    }

    /*logout popup*/
    onLogoutPopupOpen() {
        this.userMenuOpened = false;
        this.logoutPopupOpened = true;
    }
    onLogoutPopupClose() {
        this.logoutPopupOpened = false;
    }
    onLogout() {
        this.Auth.logout();
    }

    /*user data*/
    getUserName(): string {
        return (this.userData.firstName !== '' && this.userData.lastName !== '') ? (this.userData.firstName + ' ' + this.userData.lastName) : this.userData.email;
    }

}
