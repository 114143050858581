import { Component, OnInit } from '@angular/core';
import { User } from '../_shared/models/user.model';
import { AuthService } from '../_shared/services/auth.service';
import { ForgotPasswordService } from '../portal/forgotpassword/forgotpassword.service';

@Component({
    selector: 'app-password',
    templateUrl: './password.component.html',
    styles: []
})
export class PasswordComponent implements OnInit {
    private userData: User;
    changeSuccess = false;
    loaderIsVisible = false;
    errorValue = 0;
    constructor(private Auth: AuthService, private forgotPasswordService: ForgotPasswordService) { }

    ngOnInit() {
        this.userData = this.Auth.decodeToken();
    }

    getEmail(): string {
        return this.userData.email;
    }
    getUserName(): string {
        return this.userData.userName;
    }

    onSubmit() {
        this.loaderIsVisible = true;
        this.forgotPasswordService.requestChange(this.getUserName())
            .subscribe(
                (response: string) => {
                    this.loaderIsVisible = false;
                    console.log('%c' + response, 'color: green');
                    this.changeSuccess = true;
                },
                (error) => {
                    this.loaderIsVisible = false;
                    console.log('%c' + error, 'color: red');
                    this.errorValue = -1;
                }
            );
    }

}
