import { Component, OnInit } from '@angular/core';
import {
    trigger,
    style,
    animate,
    transition,
    query,
    group
} from '@angular/animations';

@Component({
    selector: 'app-portal',
    templateUrl: './portal.component.html',
    styles: [],
    animations: [
        trigger('routerAnimation', [
            transition('* <=> *', [
                query(':enter, :leave', style({ position: 'fixed', width: '100%' }), { optional: true }),
                group([
                    query(':enter', [
                        style({ opacity: '0', transform: 'scale(0.8)' }),
                        animate('0.5s cubic-bezier(0.8, 0, 0.2, 1)', style({ opacity: '1', transform: 'scale(1)' })),
                    ], { optional: true }),
                    query(':leave', [
                        style({ opacity: '1', transform: 'scale(1)' }),
                        animate('0.5s cubic-bezier(0.8, 0, 0.2, 1)', style({ opacity: '0', transform: 'scale(1.2)' })),
                    ], { optional: true }),
                ])
            ])
        ])
    ]
})
export class PortalComponent implements OnInit {

    bgArr: any = ['allstream-montreal_g.jpg', 'allstream-toronto_g.jpg', 'allstream-vancouver_g.jpg'];
    bgActive: any;
    constructor() {
        this.bgActive = this.bgArr[Math.floor(Math.random() * this.bgArr.length)];
    }

    ngOnInit() { }

    getRouteAnimation(outlet) {
        return outlet.activatedRouteData.animation;
    }

}
