import { Directive, OnInit, HostListener, ElementRef } from '@angular/core';

@Directive({ selector: '[appClickInput]' })
export class ClickInputDirective implements OnInit {
    constructor(private elementRef: ElementRef) {}
    ngOnInit() {
        if (this.elementRef.nativeElement.value) {
            this.elementRef.nativeElement.parentNode.classList.add('typed');
        }
    }

    @HostListener('ngModelChange') modelChanges() {
        if (this.elementRef.nativeElement.value) {
            this.elementRef.nativeElement.parentNode.classList.add('typed');
        } else {
            this.elementRef.nativeElement.parentNode.classList.remove('typed');
        }
    }

    @HostListener('focus') inputFocus() {
        this.elementRef.nativeElement.parentNode.classList.add('focus');
    }

    @HostListener('blur') inputBlur() {
        this.elementRef.nativeElement.parentNode.classList.remove('focus');
    }

    @HostListener('keyup') inputKeyup() {
        if (this.elementRef.nativeElement.value) {
            this.elementRef.nativeElement.parentNode.classList.add('typed');
        } else {
            this.elementRef.nativeElement.parentNode.classList.remove('typed');
        }
    }
}
