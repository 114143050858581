import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'didFormat'
})
export class DidFormat implements PipeTransform {
    transform(value: string): string {
        return value.replace(/^(\w{0,3}).(\w{0,3}).(\w{0,})/, '($1) $2-$3');
    }
}
