import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ResetPasswordService } from './resetpassword.service';

@Component({
    selector: 'app-resetpassword',
    templateUrl: './resetpassword.component.html',
    styles: []
})
export class ResetpasswordComponent implements OnInit {
    resetpasswordForm: FormGroup;
    isSubmitted = false;
    errorValue = 0;
    loaderIsVisible = false;
    resetSuccess = false;
    containsSpecialCharacter = false;
    containsLetter = false;
    containsNumber = false;
    containsLength = false;
    constructor(private route: ActivatedRoute, private resetPasswordService: ResetPasswordService) { }

    ngOnInit() {
        this.resetpasswordForm = new FormGroup({
            'newpassword': new FormControl(null, [Validators.required, Validators.pattern(/(?=^[^\s]{8,16}$)(?=.*\d.*)(?=.*[a-zA-Z].*)(?=.*[!@#$%^&*()_\-+=]*.*).*/)]),
            'repeatpassword': new FormControl(null, Validators.required),
        }, this.confirmPassword);
    }

    onSubmit() {
        this.isSubmitted = true;
        if (this.resetpasswordForm.valid) {
            this.loaderIsVisible = true;
            this.resetPasswordService.requestReset(this.resetpasswordForm.get('newpassword').value, this.route.snapshot.queryParams['tokenId'])
                .subscribe(
                    (response: string) => {
                        this.loaderIsVisible = false;
                        console.log('%c' + response, 'color: green');
                        this.resetSuccess = true;
                    },
                    (error) => {
                        this.loaderIsVisible = false;
                        console.log('%c' + error, 'color: red');
                        this.errorValue = -1;
                    }
                );
        }
    }

    confirmPassword(control: AbstractControl) {
        const password = control.get('newpassword').value;
        const confirmPassword = control.get('repeatpassword').value;
        if (password !== confirmPassword) {
            control.get('repeatpassword').setErrors({ confirmPassword: true });
        } else {
            return null;
        }
    }

    validateRequirements(event: any) {
        const value = event.target.value;
        if (/[!@#$%^&*()_\-+=]/g.test(value)) {
            this.containsSpecialCharacter = true;
        } else {
            this.containsSpecialCharacter = false;
        }
        if (/[a-zA-Z]/g.test(value)) {
            this.containsLetter = true;
        } else {
            this.containsLetter = false;
        }
        if (/[0-9]/g.test(value)) {
            this.containsNumber = true;
        } else {
            this.containsNumber = false;
        }
        if (/^[^\s]{8,16}$/g.test(value)) {
            this.containsLength = true;
        } else {
            this.containsLength = false;
        }
    }

}
