import { Component, OnInit, OnDestroy, ViewChildren, ViewChild, QueryList } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../_shared/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../_shared/services/auth.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
    selector: 'app-inventory-detail',
    templateUrl: './inventory-detail.component.html'
})
export class InventoryDetailComponent implements OnInit, OnDestroy {
    currentPage = 1;
    itemsPerPage = 10;
    endItemPerPage = 10;
    startItemPerPage = 0;
    tableMenuOpened = false;
    editPopupOpened = false;
    loaderIsVisible = false;
    sort: any = {
        column: '',
        descending: false
    };
    filterForm: FormGroup;
    collectionSize: any;
    desc = false;
    filterWithSorting: string;
    inventoryArr: any = [];
    editArray: any;
    detailPilotNumber: any = {};
    private sub: any;
    id: any;
    searchTextTns: string;
    searchText: string;
    searchTextByValue: string;
    valueField: string;
    selectAll = false;
    selectValAll = false;
    editPagesLength = 1;
    numberStartPage = 0;
    numberEndPage = 1;
    disablePrevButton = true;
    disableNextButton = true;
    subscribeId: any;
    numberSubscribeId: any;
    pilotNumber: any;
    loader911Object = false;
    errorServer = false;
    editError = false;
    saveData = false;
    isSubmitted = false;
    isDisabled = false;
    errorText: string;
    editNumbersForm: FormGroup;
    loaderPage = true;
    didNumber: any;
    patternCode = '^(?!.*[DFIOQU])[A-VXY][0-9][A-Z] ?[0-9][A-Z][0-9]$';
    subscr: any;
    subscrDisabled: any;
    DIDPopupOpened = false;
    pilotNumberICC: string;

    constructor(private api: ApiService, private route: ActivatedRoute, private Auth: AuthService) {
    this.filterForm = new FormGroup({
        searchVal: new FormControl('')
    });

    this.subscribeId = Auth.getDecodedToken();
    this.numberSubscribeId = this.subscribeId.user_details.iccId;

    this.editNumbersForm = new FormGroup({
    telephoneNumber: new FormControl(null),
    firstName: new FormControl(null),
    lastName: new FormControl(null, Validators.required),
    streetNumber: new FormControl(null, Validators.required),
    streetAddress: new FormControl(null, Validators.required),
    suite_Apt: new FormControl(null),
    city: new FormControl(null, Validators.required),
    province_State: new FormControl(null, Validators.required),
    postalCode: new FormControl(null, [Validators.required, Validators.pattern(this.patternCode)]),
    country: new FormControl(null, Validators.required),
    });
    this.searchTextTns = '';
    this.sub = this.route.params.subscribe(params => {
        this.pilotNumber = +params['id'];
    });
    this.valueField = 'telephoneNumber';

    }

    /*pagination*/

    selectPage(page: any) {
        this.currentPage = page;
        this.endItemPerPage = this.itemsPerPage * page;
        this.startItemPerPage = this.endItemPerPage - this.itemsPerPage;
    }

    setItemPerPage(num: any) {
        this.itemsPerPage = num;
        this.endItemPerPage = this.itemsPerPage;
        this.startItemPerPage = 0;
    }

    /*sorting functions*/

    getSortClass(value) {
        if (this.sort.column === value && this.sort.descending) { return 'asc'; } else if (this.sort.column === value && !this.sort.descending) { return 'desc'; }
    }

    changeSorting(column) {
        const sort = this.sort;
        if (sort.column === column) {
            sort.descending = !sort.descending;
        } else {
            sort.column = column;
            sort.descending = false;
        }
    }

    changeFilterSorting(val) {
        this.filterWithSorting = val;
        this.desc = !this.desc;
    }

    getSortFilterClass(val) {
        if (this.filterWithSorting === val && this.desc) { return 'active asc'; } else if (this.filterWithSorting === val && !this.desc) { return 'active desc'; }
    }

    ngOnInit() {
        this.getInventoryListDetail();
        this.route.queryParams.subscribe(params => {
            this.pilotNumberICC = params.ICC;
            });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    /*get list of numbers function*/

    getInventoryListDetail() {
        this.loaderIsVisible = true;
        this.api.getInventoryDetail(this.numberSubscribeId, this.pilotNumber).subscribe(
        (response: any) => {
            this.detailPilotNumber = response;
            console.log(this.detailPilotNumber);
            this.inventoryArr = response.telephoneNumbers;
            this.collectionSize =  this.inventoryArr.length;
            this.loaderIsVisible = false;
            this.loaderPage = false;
        });
    }

    exportFile() {
        const options = {
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalseparator: '.',
            showLabels: true,
            showTitle: true,
            useBom: true,
            noDownload: false,
            headers: ['TNs', 'Status']
        };
        const data = this.detailPilotNumber.telephoneNumbers;
        return new AngularCsv(data, 'Report_' + this.detailPilotNumber.pilotNumber, options);
    }

    /*toggle all items to edit*/

    // 	toggleAllSelect(){
    // 		this.editArray = [];
    // 		if (this.selectAll == true){
    // 			this.inventoryArr.map((i)=>{i.isActive = true; this.editArray.push(i)});
    // 			this.getNumberEditPages();
    // 		}else{
    // 			this.inventoryArr.map((i)=>{i.isActive = false});
    // 			this.editArray = [];
    // 			this.editPagesLength = 0;
    // 		}
    // 	}

    /*toggle item to edit*/

    // 	editNumberSelect($event, item){
    //        const index: number = this.editArray.indexOf(item);
    // 		  if (item.isActive == true) {
    // 		     this.editArray.push(item);
    // 		  }else{
    // 			 if (index !== -1) {
    // 				this.editArray.splice(index, 1);
    // 			 }
    // 		  }
    // 		this.getNumberEditPages();
    // 	}

    /*edit popup pagination*/

    // 	nextEditPage(){
    // 		this.disableNextButton = false;
    // 		if (this.numberEndPage < this.editPagesLength) {
    // 			this.numberStartPage++;
    // 	        this.numberEndPage++;
    // 			this.disablePrevButton = false;
    // 	        if ( (this.numberStartPage + 1) == this.editPagesLength) {
    // 				this.disableNextButton = true;
    // 			}
    // 		}
    // 	}
    //
    // 	prevEditPage(){
    // 		this.disablePrevButton = false;
    // 		if (this.numberStartPage >= 1){
    // 			this.numberStartPage--;
    // 	        this.numberEndPage--;
    // 			this.disableNextButton = false;
    // 			if (this.numberEndPage == 1) {
    // 				this.disablePrevButton = true;
    // 			}
    // 		}
    // 	}
    //
    // 	getNumberEditPages(){
    // 		this.editPagesLength = +this.editArray.length;
    // 		if (this.editPagesLength > 1) {
    // 			this.disableNextButton = false
    // 		}
    // 	}
    //
    /*filter number list*/

    filterArray() {
        this.sort = {
            column: this.filterWithSorting,
            descending: this.desc
        };
        if (this.searchTextByValue) {
            this.searchTextTns = '';
            this.searchText = this.searchTextByValue;
        }
    }

    filterSearch() {
        this.searchText = this.searchTextTns;
    }

    /*reset filter numbers list*/

    resetFilter() {
        this.sort = {
            column: '',
            descending: false
        };
        this.searchText = '';
        this.filterWithSorting = '';
        this.searchTextByValue = '';
        this.searchTextTns = '';
        this.getInventoryListDetail();
    }

    /**/

    onOpenEditPopup(item: any) {
        let objData: any = {};
        this.editPopupOpened = true;
        this.loader911Object = true;
        this.editError = false;
        this.errorText = '';
        this.saveData = false;
        this.didNumber = item.telephoneNumber;
        this.isSubmitted = false;

        this.editNumbersForm.reset();
        this.editNumbersForm.updateValueAndValidity();
        console.log(this.editNumbersForm);
        this.api.get911Object(item.telephoneNumber).subscribe(
        (response: any) => {
            objData = response;
            this.editNumbersForm.patchValue(objData);

            this.loader911Object = false;
            this.errorServer = false;
        }, (error) => {
            if (error.status === 404) {
                this.editNumbersForm.get('telephoneNumber').setValue(item.telephoneNumber);
                this.isSubmitted = false;
                this.loader911Object = false;
                this.errorServer = false;
            } else {
               this.loader911Object = false;
               this.errorServer = true;
            }
        });
    }

    editForm() {
        this.saveData = false;
        this.editError = false;
        this.isSubmitted = false;
        if (this.editNumbersForm.valid) {
            this.loader911Object = true;
            this.editNumbersForm.reset(this.editNumbersForm.value);
            this.api.update911Object(this.didNumber, this.editNumbersForm.value).subscribe(
            (response: any) => {
            if (response) {
                this.saveData = true;
                this.editError = false;
                this.loader911Object = false;
            }
            }, (error) => {
                this.loader911Object = false;
                this.saveData = false;
                this.editError = true;
                this.errorText = error.statusText;
            });
        } else {
            this.isSubmitted = true;
//            this.subscr = this.editNumbersForm.valueChanges.subscribe(() => {
//                if (this.editNumbersForm.valid){
//                    this.isSubmitted = false;
//                }else{
//                    this.isSubmitted = true;
//                }
//            });
        }
    }

    onCloseEditPopup() {
        console.log('close popup');
        this.editPopupOpened = false;
        this.saveData = false;

//        if (this.subscr){
//            this.subscr.unsubscribe();
//            this.isSubmitted = false;
//        }
    }

    onToggleTableMenu() {
        this.tableMenuOpened = !this.tableMenuOpened;
    }
    onCloseTableMenu() {
        this.tableMenuOpened = false;
    }

    /*DID popup*/
    onOpenDID() {
        this.DIDPopupOpened = true;
    }
    onCloseDID() {
        this.DIDPopupOpened = false;
    }

}
